import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              My journey into programming started with a deep curiosity for problem-solving and
              optimization. While playing chess, I became fascinated by algorithms and
              decision-making processes. This curiosity led me to explore programming more formally,
              and I quickly immersed myself in development, honing my skills through hands-on
              projects and continuous learning.
            </p>

            <p>
              Since then, I've had the opportunity to work across diverse industries—including{' '}
              <a href="https://cadrums.com/">a drum shop</a>,{' '}
              <a href="http://spacelab.space/">a space technology company</a>, and{' '}
              <a href="https://tulareunionmusic.com/">my alma mater's band program</a>—before
              transitioning into my current role at{' '}
              <a href="https://jdhco.com/">JDH (J.D. Heiskell & Co.)</a>. There, I focus on building{' '}
              <strong>internal tools and user enhancements</strong> across four business divisions,
              optimizing workflows, streamlining logistics, and improving operational efficiency.
            </p>

            <p>
              Beyond work, I help lead{' '}
              <a href="https://www.darkskypercussion.org/">Dark Sky Percussion</a>, a nonprofit
              competitive ensemble, where I oversee operations, logistics, and strategic
              initiatives. I also stay engaged with modern web technologies like{' '}
              <strong>HTMX and React</strong>—often with a cold brew in hand.
            </p>

            <p>Here are a few technologies I've been working with recently:</p>
          </div>

          <ul className="skills-list">
            <li>
              <strong>Languages:</strong> JavaScript, Ruby on Rails, Node.js, Python (Pandas),
              Express
            </li>
            <li>
              <strong>Frontend & UI:</strong> React, HTMX, AlpineJS, Tailwind, HTML, (S)CSS
            </li>
            <li>
              <strong>Databases & Backend:</strong> MySQL, Postgres
            </li>
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
